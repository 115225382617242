import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import goTo from "vuetify/lib/services/goto";
Vue.use(VueRouter);

const routes = [
  {
    path: "/kinplus",
    alias: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  // {
  //   path: "/services",
  //   name: "Services",
  //   component: () =>
  //     import(/* webpackChunkName: "services" */ "@/views/Services.vue"),
  // },
  {
    path: "/training",
    name: "Training",
    component: () =>
      import(/* webpackChunkName: "Training" */ "@/views/Training.vue"),
  },
  {
    path: "/it-siwes",
    name: "Siwes",
    component: () =>
      import(/* webpackChunkName: "it-siwes" */ "@/views/Siwes.vue"),
  },
  {
    path: "/adminlogin",
    name: "AdminLogin",
    component: () =>
      import(
        /* webpackChunkName: "adminlogin" */
        "@/views/admin/AdminLogin.vue"
      ),
  },

  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () =>
  //     import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  // },
  {
    path: "/",
    name: "Dashboard",
    redirect: "/dashboard-d",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */
        "@/views/admin/AdminDashboard.vue"
      ),
    children: [
      {
        path: "dashboard-d",
        name: "AllDashboard",
        redirect: "",
        component: () =>
          import(
            /* webpackChunkName: "dashboard-d" */
            "@/views/admin/AllDashboard.vue"
          ),
      },
      {
        path: "dashboard-d-adminInformation",
        name: "AdminsInfo",
        component: () =>
          import(
            /* webpackChunkName: "adminInformation" */ "@/views/admin/AdminInfo.vue"
          ),
      },

      {
        path: "dashboard-d-traineesInformation",
        name: "TraineesInfo",
        component: () =>
          import(
            /* webpackChunkName: "traineesInformation" */
            "@/views/admin/TraineesInfo.vue"
          ),
      },
      {
        path: "dashboard-d-internsInformation",
        name: "InternsInfo",
        component: () =>
          import(
            /* webpackChunkName: "internsInformation" */
            "@/views/admin/InternsInfo.vue"
          ),
      },
      {
        path: "dashboard-d-skillupInformation",
        name: "SkillupInfo",
        component: () =>
          import(
            /* webpackChunkName: "skillupInformation" */
            "@/views/admin/SkillupInfo.vue"
          ),
      },
      {
        path: "dashboard-d-kipInformation",
        name: "KipInfo",
        component: () =>
          import(
            /* webpackChunkName: "kipInformation" */
            "@/views/admin/KipInfo.vue"
          ),
      },
      {
        path: "dashboard-d-siwesInformation",
        name: "SiwesInfo",
        component: () =>
          import(
            /* webpackChunkName: "siwesInformation" */
            "@/views/admin/SiwesInfo.vue"
          ),
      },
      {
        path: "dashboard-d-contactsInformation",
        name: "ContactsInfo",
        component: () =>
          import(
            /* webpackChunkName: "contactsInformation" */
            "@/views/admin/ContactsInfo.vue"
          ),
      },
    ],
  },

  {
    path: "/interns",
    alias: "/intern",
    name: "Internship",
    props: (route) => ({
      routeTitle: route.params.routeTitle,
      editUser: route.params.editUser,
    }),
    component: () =>
      import(/* webpackChunkName: "interns" */ "@/views/Intern/Interns.vue"),
  },
  {
    path: "/kip",
    name: "Kip",
    component: () => import(/* webpackChunkName: "kip" */ "@/views/Kip.vue"),
  },
  {
    path: "/mdev",
    alias: "/mdevs",
    name: "Mdev",
    component: () =>
      import(/* webpackChunkName: "mdev" */ "@/views/MobileDev.vue"),
  },
  {
    path: "/mdev-dashboard",
    alias: "/mdevdashboard",
    name: "MdevDashboard",
    component: () =>
      import(/* webpackChunkName: "mdev" */ "@/views/admin/MdevInfo.vue"),
  },
  {
    path: "/dsikere",
    alias: "/dstikere",
    name: "Dsikere",
    component: () =>
      import(/* webpackChunkName: "mdev" */ "@/views/Ikere.vue"),
  },
  {
    path: "/ikere-dashboard",
    alias: "/ikeredashboard",
    name: "IkereDashboard",
    component: () =>
      import(/* webpackChunkName: "mdev" */ "@/views/admin/IkereInfo.vue"),
  },
  {
    path: "/techup",
    alias: "/techupekiti",
    name: "TechUp",
    component: () =>
      import(/* webpackChunkName: "mdev" */ "@/views/TechUp.vue"),
  },
  {
    path: "/talent",
    alias: "/talents",
    name: "Talent",
    component: () =>
      import(/* webpackChunkName: "mdev" */ "@/views/Talent.vue"),
  },
  {
    path: "/partner",
    alias: "/partners",
    name: "Partner",
    component: () =>
      import(/* webpackChunkName: "mdev" */ "@/views/Partner.vue"),
  },
  {
    path: "/techup-dashboard",
    alias: "/techupdashboard",
    name: "TechupDashboard",
    component: () =>
      import(/* webpackChunkName: "mdev" */ "@/views/admin/TechupInfo.vue"),
  },
  {
    path: "/skillup",
    alias: "/skillsup",
    name: "SkillUp",
    component: () =>
      import(/* webpackChunkName: "skillup" */ "@/views/SkillUp.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/views/Contact.vue"),
  },

  {
    path: "/404",
    alias: "*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }
    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("curUser");
  const token = localStorage.getItem("token");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn && !token) {
      next({ name: "Home", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
