<template>
  <v-app>
    <v-app-bar
      :clipped-left="clipped"
      app
      elevate-on-scroll
      height="100px"
      color="primary"
      dark
    >
      <router-link class="text-decoration-none" :to="{ name: 'Home' }"
        ><h2 transition="scale-transition" class="mt-4 navtitle white--text">
          KINPLUS TECHNOLOGIES
        </h2></router-link
      >

      <v-spacer></v-spacer>
      <NavBar :color="color" v-if="!mobile" />
      <v-app-bar-nav-icon
        class="ma-3"
        v-if="mobile"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" color="primary" right temporary>
      <p class="text-end pa-2">
        <v-btn fab small @click="hideDrawer"><v-icon>mdi-close</v-icon></v-btn>
      </p>
      <NavDrawer :color="color" />
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer :clipped-left="clipped" class="primary pt-6" dark padless>
      <footer-page></footer-page>

      <v-col cols="12" class="primary text-center mt-6 white--text">
        <!-- <div>
      <p class=" text-end"><v-btn :to="{name:'AdminLogin'}" class="black--text ligthen-3" text>:</v-btn></p>
      </!-->
        <h5>
          Copyright &copy; {{ new Date().getFullYear() }} Kinplus Technologies
          All Right Reserved.
        </h5>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import NavBar from "@/components/home/NavBar.vue";
import NavDrawer from "@/components/home/NavDrawer.vue";
import FooterPage from "@/components/home/FooterPage.vue";
import { pwaMixin } from "@/mixins";
export default {
  name: "App",
  mixins: [pwaMixin],
  components: {
    NavBar,
    NavDrawer,
    FooterPage,
  },

  data() {
    return {
      clipped: true,
      fab: false,
      drawer: null,
      color: "white",
    };
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  watch: {
    mobile(newval) {
      if (!newval) {
        this.hideDrawer();
      }
    },
  },

  methods: {
    hideDrawer() {
      this.drawer = false;
    },
  },
};
</script>

<style>
* {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
.padding {
  padding: 200px;
}
.logocolor {
  color: rgb(7, 9, 95);
  font-family: poppins, sans-serif;
}
.breadcrumb {
  background-color: rgb(10, 33, 66);
  height: 300px;
  width: 100vw;
}
.hover:hover {
  color: rgb(11, 125, 160);
}

@media screen and (max-width: 500px) {
  .navtitle {
    font-size: 15px;
  }
  .nav {
    font-size: 40px;
  }
  .navh {
    font-size: 20px;
  }
}
</style>
