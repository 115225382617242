<template>
  <div>
    <HeroPage />

    <div class="mb-5 mt-10 pa-10">
      <h1 class="text-center">BUILD UP YOUR SKILLS WITH OUR PROGRAMS</h1>
      <p class="text-center">
        Kinplus Technologies offer Programs which are available and helps you
        build your skills into the Tech world
      </p>
      <v-row justify="center">
        <v-col cols="12">
          <v-card flat height="250" width="400" class="mx-auto">
            <h3 v-for="list in lists" :key="list.title">
              <v-icon color="info">mdi-arrow-right</v-icon> {{ list.title }}
            </h3>
          </v-card>
        </v-col>
      </v-row>
      <p class="text-center">
        <v-btn outlined :to="{ name: 'Training' }" class="hover">
          LEARN MORE
        </v-btn>
      </p>
    </div>

    <div class="pa-16 primary padding">
      <v-row justify="center">
        <v-col cols="12" md="6" class="align-self-center">
          <h1 class="white--text">Build Your Responsive Website</h1>
          <p class="white--text">
            Kinplus Technologies development team has enough and well
            experienced expertise with the most exquisite ideas into your
            perfect web apps. We don't just create web applications but also
            solve your bussiness problems. We are always ready to meet your high
            expectations and quality standards.
          </p>
          <div class="btn-center pt-3">
            <v-btn dark :to="{ name: 'Contact' }" color="info">
              Contact Us <v-icon class="ml-3">mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-card flat color="primary">
            <v-img src="img/home/mobile.png"></v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-container class="py-10">
      <h2 class="text-center info--text">Special Training Offer</h2>
      <v-row class="fill-center" justify="center" align="center">
        <v-col cols="12" sm="5" md="4">
          <v-img src="img/home/brain.png" height="200" width="200"></v-img>
        </v-col>
        <v-col cols="12" sm="7" md="8">
          <h4>
            Are you in Senior Secondary School, did you just finish Secondary
            School, are you a graduate or you are a serving NYSC Corps member
            residing in Ekiti State, and you want to acquire knowledge on
            Digital Skills such as Software Development, Graphics Design
            etc.This is a big opportunity to achieve your goal.
          </h4>
          <v-btn color="info" :to="{ name: 'Training' }"
            >Apply <v-icon class="ml-3">mdi-arrow-right</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeroPage from "@/components/home/HeroPage.vue";
export default {
  name: "Home",
  components: {
    HeroPage,
  },
  data() {
    return {
      viewAll: false,
      showarrow: false,
      lists: [
        { title: "Software Development" },
        { title: "UI/UX Design" },
        { title: "Graphics Design" },
        { title: "Project Management" },
        { title: "IT Automation & IoT " },
        { title: "Hardware Maintainance & Repair" },
        { title: "Data Science/Analysis" },
      ],
    };
  },
};
</script>

<style scoped>
.port:hover {
  transition: opacity 0.4s ease-in-out;
}

.port:hover {
  opacity: 0.6;
}
</style>
