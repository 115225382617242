<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="6" md="4">
        <v-card color="primary" height="60" width="150" flat>
          <v-img src="img/kinplus-white.png" contain></v-img>
        </v-card>

        <p class="mt-3">
          Kinplus Technologies is a software development company focused on
          building scalable applications and software for businesses, corporate
          organisations, and individuals.
        </p>
      </v-col>

      <v-col cols="12" sm="6" md="4" class="mx-auto">
        <b>LINKS</b>
        <p class="mt-3" v-for="(link, index) in links" :key="index">
          <router-link
            :to="{ name: link.link }"
            class="text-decoration-none hover"
            ><v-list-item-title class="hover white--text">{{
              link.title
            }}</v-list-item-title></router-link
          >
        </p>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="mx-auto">
        <h4>ADDRESS:</h4>
        <p class="white--text">
          2nd Floor, Christore Building, <br />Opp. Crunchies Restaurant,<br />
          Similoluwa, Ado Ekiti, Ekiti State, Nigeria.<br />
          Email: help@kinplusgroup.com <br />
          Phone: +2347075199782, +2348116400858
        </p>
        <div class="d-block">
          <!-- <a  class="d-block " target="blank" href="https://chat.whatsapp.com/LLsPklfyuhg1h1atsaOH38"><v-icon title="Whatsapp">mdi-whatsapp</v-icon> <span class="white--text">Whatsapp</span> </a> -->
          <a
            class="d-block"
            target="blank"
            href="https://chat.whatsapp.com/EdkEX1uhZpc9slDU93LYz2"
            ><v-icon title="Whatsapp">mdi-whatsapp</v-icon>
            <span class="white--text">Whatsapp</span>
          </a>
          <!-- https://chat.whatsapp.com/GW1NVU8Xq8DClmwosdSJQG  -- Kinplus 1 Whatsapp -->
          <!-- https://chat.whatsapp.com/EdkEX1uhZpc9slDU93LYz2  -- Kinplus 2 Whatsapp -->
          <a
            class="d-block"
            target="blank"
            href="https://www.facebook.com/kinplustechnologies"
            ><v-icon title="Kinplus Facebook Handle">mdi-facebook</v-icon>
            <span class="white--text">Facebook</span>
          </a>
          <a
            class="d-block mt-1"
            target="blank"
            href="mailto:help@kinplusgroup.com"
            ><v-icon title="Email">mdi-email</v-icon>
            <span class="white--text">Email</span></a
          >
          <a
            class="d-block mt-1"
            target="blank"
            href="https://t.me/kinplusgroup"
            ><v-icon title="Kinplus Telegram Group">mdi-telegram</v-icon>
            <span class="white--text">Telegram</span></a
          >
          <a
            class="d-block mt-1"
            target="blank"
            href="https://twitter.com/kinplustech"
            ><v-icon title="Kinplus Twitter Handle">mdi-twitter</v-icon>
            <span class="white--text">Twitter</span></a
          >
          <a
            class="d-block mt-1"
            target="blank"
            href="https://www.linkedin.com/company/kinplustechnologies"
            ><v-icon title="Kinplus LinkedIn Handle">mdi-linkedin</v-icon>
            <span class="white--text">LinkedIn</span></a
          >
          <a
            class="d-block mt-1"
            target="blank"
            href="https://www.instagram.com/kinplustechnologies"
            ><v-icon title="Kinplus Instagram Handle">mdi-instagram</v-icon>
            <span class="white--text">Instagram</span></a
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FooterPage",
  data() {
    return {
      // btns: [
      //   { icon: "whatsapp", title: "Whatsapp", link: "https://w.me/+2347069718643" },
      //   { icon: "facebook", title: "facebbok", link: "" },
      //   { icon: "linkedin", title: "linkedin", link: "" },
      //   { icon: "mail", title: "mail", link: "" },
      //   { icon: "twitter", title: "twitter", link: "" },
      // ],

      links: [
        { title: "Homepage", link: "Home" },
        { title: "Know more about us", link: "About" },
        { title: "Our training programs", link: "Training" },
        { title: "Get in touch with us", link: "Contact" },
        { title: "Internship programs", link: "Internship" },
        { title: " IT / SIWES program", link: "Siwes" },
      ],
    };
  },
};
</script>
