<template>
  <div class="mt-4 mr-5">
    <v-btn small text :to="{ name: 'Home' }" :color="color" class="mx-1">
      Home
    </v-btn>
    <v-btn small text :to="{ name: 'About' }" :color="color" class="mx-1">
      About
    </v-btn>
    <v-btn small text :to="{ name: 'Training' }" :color="color" class="mx-1">
      Training
    </v-btn>

    <v-btn small text :to="{ name: 'Contact' }" :color="color" class="mx-1">
      Contact
    </v-btn>
    <!-- <v-btn text :to="{ name: 'Internship' }" :color="color" class="mx-1">
      Internship
    </v-btn> -->
    <v-btn text :to="{ name: 'Siwes' }" :color="color" class="mx-1">
      IT / SIWES
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "NavBar",

  props: {
    color: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {};
  },
};
</script>
