<template>
  <div>
    <div class="bg">
      <div class="centered-text px-2">
        <v-row class="align-center">
          <v-col cols="12">
            <h1 class="home-text info--text">
              we
              <vue-typer
                class="typer"
                :text="[
                  'DEVELOP',
                  'TRAIN',
                  'BUILD CONFIDENCE',
                  'CREATE IDENTITY',
                ]"
                :repeat="Infinity"
                :shuffle="false"
                initial-action="erasing"
                :pre-type-delay="200"
                :type-delay="200"
                :pre-erase-delay="3000"
                :erase-delay="6000"
                erase-style="clear"
                :erase-on-complete="false"
                caret-animation="blink"
              ></vue-typer>
            </h1>
          </v-col>

          <v-col cols="12">
            <p class="word">
              Kinplus Technologies helps in building scalable applications and
              software for businesses, cooperate organisations, and individuals.
            </p>
          </v-col>
        </v-row>
      </div>

      <vue-particles
        class="particle"
        color="#9dc9df"
        :particleOpacity="0.6"
        :particlesNumber="100"
        shapeType="circle"
        :particleSize="5"
        linesColor="#2196F3"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="70"
        :moveSpeed="5"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
      </vue-particles>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroPage",
  props: {
    mobile: {
      type: Boolean,
    },
  },
};
</script>

<style>
.bg {
  background: url(/img/bg.jpg);
  background-position: center;
  height: 700px;
  background-size: cover;
  border-bottom: rgba(9, 59, 105, 0.993) 1rem solid;
}
.particle {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
}

.typer {
  font-size: 40px;
}
.centered-text {
  color: #271616;
  text-align: justify;
  position: absolute;
  left: 10%;
  top: 10%;
  max-width: 500px;
}
.word {
  color: #f7f7fc;
}

.vue-typer .custom.char.typed {
  color: #9dc9df;
}
.vue-typer .custom.char.selected {
  color: #67818d;
  background-color: transparent;
  text-decoration: line-through;
}

.vue-typer .custom.caret {
  display: none;
}

@media screen and (max-width: 600px) {
  .typer {
    font-size: 30px;
  }

  .bg {
    background: url(/img/bg.jpg);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom: rgba(9, 59, 105, 0.993) 1rem solid;
  }

  .centered-text {
    position: absolute;
    text-align: justify;
    top: 8%;
    left: 7%;
    max-width: 650px;
  }

  .word {
    color: #f7f7fc;
  }

  .home-text {
    font-size: 15px;
  }
}
</style>
