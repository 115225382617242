import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueParticles from "vue-particles";
import VueTyperPlugin from "vue-typer";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueExcelXlsx);

Vue.use(VueParticles);
Vue.use(VueTyperPlugin);

Vue.config.productionTip = false;

Vue.filter("formatDate", function (value) {
  if (!value) return "";
  let dt = new Date(value);
  let day = +dt.getDate();
  day = day < 10 ? "0" + day : day;
  let month = +dt.getMonth();
  month = month < 10 ? "0" + month : month;
  return `${day}-${month}-${dt.getFullYear()}`;
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
