<template>
  <div class="mt-10 pt-6">
    <div>
      <v-btn text :to="{ name: 'Home' }" :color="color" class="mx-1">
        home
      </v-btn>
    </div>

    <div>
      <v-btn text :to="{ name: 'About' }" :color="color" class="mx-1">
        About
      </v-btn>
    </div>
    <!-- <div class="pa-3">
      <v-btn text :to="{ name: 'Services' }" :color="color" class="mx-1">
        Services
      </v-btn>
    </div> -->
    <div>
      <v-btn text :to="{ name: 'Training' }" :color="color" class="mx-1">
        Training
      </v-btn>
    </div>
    <!-- <div>
      <v-btn text :to="{ name: 'Mdev' }" :color="color" class="mx-1">
        MDev
      </v-btn>
    </div> -->

    <div>
      <v-btn text :to="{ name: 'Contact' }" :color="color" class="mx-1">
        Contact
      </v-btn>
    </div>

    <div>
      <!-- <v-btn text :to="{ name: 'Internship' }" :color="color" class="mx-1">
        INTERNSHIP
      </v-btn> -->
    </div>
    <div>
      <v-btn text :to="{ name: 'Siwes' }" :color="color" class="mx-1">
        IT / SIWES
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavDrawer",
  props: {
    color: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.nav {
  display: block;
}
</style>
