import Vue from "vue";
import Vuex from "vuex";
import { apiClient } from "@/services";

// const localStorageToken = localStorage.getItem('token') || ''
// const localStorageCurUser = JSON.parse(localStorage.getItem('curUser')) || {}

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // admins: [],
    // curUser: localStorageCurUser,
    // token: localStorageToken,
    curUser: {},
    token: "",
    login: false,
    activeUser: null,
    genders: ["Male", "Female"],
    packages: ["3 months", "5 months"],
    religions: ["Christianity", "Islam", "Traditional", "Others"],
    experienceLevel: [
      "No Experience",
      "Beginner - can use word for document",
      "Intermediate - can use powerpoint for presentation",
      "Expert - can code/design",
    ],
    periods: [
      "Morning: 9:00am-12:30pm",
      "Afternoon: 1pm-4pm",
      "Weekend: Saturday, 10am-4pm",
    ],
    qualifications: [
      "PhD or Equivalent",
      "Master or Equivalent",
      "Bachelor or Equivalent",
      "HND or Equivalent",
      "ND or Equivalent",
      "NCE or Equivalent",
      "SSCE",
      "SSS Class",
      "JSS Class",
    ],
    programs: [
      "SOFTWARE DEVELOPMENT",
      "UI/UX DESIGN",
      "GRAPHIC DESIGN",
      "PROJECT MANAGEMENT",
      "DATA ANALYTICS/SCIENCE",
      "DATA SCIENCE/ANALYSIS",
      "IT AUTOMATION & IOT",
      "HARDWARE MAINTANANCE & REPAIRS",
      "CYBER SECURITY",
    ],
    trainingCenters: ["EKSU", "Ado-Ekiti", "Ido-Ekiti", "Ikole-Ekiti"],
    ngstates: [
      "Abia",
      "Adamawa",
      "Akwa Ibom",
      "Anambra",
      "Bauchi",
      "Bayelsa",
      "Benue",
      "Borno",
      "Cross River",
      "Delta",
      "Ebonyi",
      "Edo",
      "Ekiti",
      "Enugu",
      "Gombe",
      "Imo",
      "Jigawa",
      "Kaduna",
      "Kano",
      "Katsina",
      "Kebbi",
      "Kogi",
      "Kwara",
      "Lagos",
      "Nassarawa",
      "Niger",
      "Ogun",
      "Ondo",
      "Osun",
      "Oyo",
      "Plateau",
      "Rivers",
      "Sokoto",
      "Taraba",
      "Yobe",
      "Zamfara",
      "FCT",
      "others",
    ],
    eklgas: [
    "Ado-Ekiti",
    "Aiyekire (Gbonyin)",
    "Efon",
    "Ekiti East",
    "Ekiti South West",
    "Ekiti West",
    "Emure",
    "Ido-Osi",
    "Ijero",
    "Ikere",
    "Ikole",
    "Ilejemeje",
    "Irepodun/Ifelodun",
    "Ise/Orun",
    "Moba",
    "Oye",
    "Others"
    ]
  },
  mutations: {
    SET_LOGIN: (state, payload) => (state.login = payload),
    // SET_ADMINS: (state, payload) => {
    //   console.log(payload);
    //   return state.admins = payload
    // },
    // SET_TOKEN: (state, payload) => (state.token = payload),
    SET_CUR_USER(state, userData) {
      localStorage.setItem("curUser", JSON.stringify(userData.admin));
      localStorage.setItem("token", userData.token);
      apiClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${userData.token}`;
      state.curUser = userData.admin;
      state.token = userData.token;
    },

    ACTIVE_USER(state, payload) {
      state.activeUser = payload;
    },

    LOGOUT() {
      localStorage.removeItem("curUser");
      localStorage.removeItem("token");
      location.reload();
    },
  },
  actions: {
    setLogin: ({ commit }, payload) => commit("SET_LOGIN", payload),
    setCurUser: ({ commit }, userData) => commit("SET_CUR_USER", userData),
    // setAdmins: ({ commit }, payload) => commit("SET_ADMINS", payload),
    // setToken: ({ commit }, payload) => commit("SET_TOKEN", payload),
    setActiveUser({ commit }, payload) {
      commit("ACTIVE_USER", payload);
    },
    logout({ commit }) {
      commit("LOGOUT");
    },
  },
  getters: {
    getAdminLevel: (state) => {
      return state.curUser.role;
    },
    getCurUser: (state) => state.curUser,
    // getAdmins: (state) => {
    //   console.log(state);
    //   return 'hello'
    //   // state.curUser.role
    // }
  },

  modules: {},
});
